import { capitalCase } from "change-case";
import { RHFSelect } from "src/components/hook-form";
import Map from "src/components/map";
import useLocales from "src/hooks/useLocales";

const AmountType = () => {
  const { translate } = useLocales();

  return (
    <RHFSelect
      name="payment_type"
      label={translate("userFinancial.eWallet.amountType")}
      size="small"
    >
      <Map
        list={options}
        render={({ value, label }) => <Option value={value} label={label} />}
      />
    </RHFSelect>
  );
};

const Option = ({ value, label }) => {
  const { translate } = useLocales();

  return <option value={value}>{capitalCase(translate(label))}</option>;
};

const options = [
  { value: "all", label: "userFinancial.depositWallet.all" },
  {
    value: "deducted_by_admin",
    label: "userFinancial.depositWallet.deductedByAdmin",
  },
  {
    value: "credited_by_admin",
    label: "userFinancial.depositWallet.creditedByAdmin",
  },
  {
    value: "product_purchased",
    label: "product_purchased",
  },
  {
    value: "fund_transfer",
    label: "userFinancial.depositWallet.fundTransfer",
  },
  {
    value: "deposit_wallet",
    label: "userFinancial.depositWallet.depositWallet",
  },

  { value: "binary_bonus", label: "userFinancial.depositWallet.binaryBonus" },
];

export default AmountType;
