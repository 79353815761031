import { useTranslation } from "react-i18next";

import { useEffect, useState } from "react";

const LANGS = [
  {
    label: "English",
    value: "en",
    icon: "/assets/icons/flags/ic_flag_en.svg",
  },

  {
    label: "Mongolian",
    value: "mn",
    icon: "/assets/icons/flags/ic_flag_mn.svg",
  },

  {
    label: "Russian",
    value: "ru",
    icon: "/assets/icons/flags/ic_flag_ru.svg",
  },

  {
    label: "Chinese",
    value: "cn",
    icon: "/assets/icons/flags/ic_flag_cn.svg",
  },
  {
    label: "Korean",
    value: "ko",
    icon: "/assets/icons/flags/ic_flag_kr.svg",
  },
  {
    label: "Arabic",
    value: "ar",
    icon: "/assets/icons/flags/ic_flag_ar.svg",
  },

  {
    label: "Kahazkh",
    value: "kk",
    icon: "/assets/icons/flags/ic_flag_kz.svg",
  },
  {
    label: "vietnamese",
    value: "vi",
    icon: "/assets/icons/flags/ic_flag_vn.svg",
  },
  {
    label: "Persian",
    value: "ir",
    icon: "/assets/icons/flags/ic_flag_ir.svg",
  },
  {
    label: "Hindi",
    value: "hi",
    icon: "/assets/icons/flags/ic_flag_hi.svg",
  },

  // {
  //   label: "Spanish",
  //   value: "es",
  //   icon: "/assets/icons/flags/ic_flag_sp.svg",
  // },
  // {
  //   label: "Portuguese",
  //   value: "pt",
  //   icon: "/assets/icons/flags/ic_flag_pt.svg",
  // },
  // {
  //   label: "German",
  //   value: "de",
  //   icon: "/assets/icons/flags/ic_flag_de.svg",
  // },
  // {
  //   label: "Japanese",
  //   value: "ja",
  //   icon: "/assets/icons/flags/ic_flag_jp.svg",
  // },
  // {
  //   label: "Italian",
  //   value: "it",
  //   icon: "/assets/icons/flags/ic_flag_ity.svg",
  // },

  // {
  //   label: "Polish",
  //   value: "pl",
  //   icon: "/assets/icons/flags/ic_flag_pl.svg",
  // },
  // {
  //   label: "Turkish",
  //   value: "tr",
  //   icon: "/assets/icons/flags/ic_flag_tr.svg",
  // },
  // {
  //   label: "French",
  //   value: "fr",
  //   icon: "/assets/icons/flags/ic_flag_fr.svg",
  // },
];
export default function useLocales() {
  const { i18n, t: translate } = useTranslation();
  const [currentLang, setCurrentLang] = useState(() => {
    const langStorage = localStorage.getItem("i18nextLng");
    if (langStorage) {
      const selectedLanguage = LANGS.find(
        (_lang) => _lang.value === langStorage
      );
      if (selectedLanguage) {
        i18n.changeLanguage(langStorage);
        return selectedLanguage;
      }
    }
    return LANGS[0];
  });

  const handleChangeLanguage = (newlang) => {
    i18n.changeLanguage(newlang);
  };

  useEffect(() => {
    localStorage.setItem("i18nextLng", i18n.language);
    const selectedLanguage = LANGS.find(
      (_lang) => _lang.value === i18n.language
    );
    setCurrentLang(selectedLanguage);
  }, [i18n.language]);
  return {
    onChangeLang: handleChangeLanguage,
    translate,
    currentLang,
    allLang: LANGS,
  };
}
