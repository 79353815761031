import { Box, Card, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import useLocales from "src/hooks/useLocales";

import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import moment from "moment";
import UsersSearch from "src/components/autoComplete/users";
import { FormProvider } from "src/components/hook-form";
import RHFDatePicker from "src/components/hook-form/RHFDatePicker";
import serializeDate from "src/utils/serialize-date";
import { object, string } from "yup";
import AmountType from "./components/amountType";

const defaultValues = {
  start_date: null,
  end_date: null,
  from_id: null,
  payment_type: "all",
};

const schema = object().shape({
  start_date: string()
    .test("is-valid", "Enter a valid date", (v) => {
      if (v === null) return true;
      return moment(v, "YYYY/MM/DD").isValid();
    })
    .test(
      "is-valid",
      "Start date should not be greater than end date",
      (v, ctx) => {
        if (v === null) return true;
        if (ctx.parent.end_date === null) return true;
        return (
          moment(ctx.parent.end_date, "YYYY/MM/DD").diff(
            moment(v, "YYYY/MM/DD")
          ) > 0
        );
      }
    )
    .transform((v) => serializeDate(v))
    .nullable(),
  end_date: string()
    .test("is-valid", "Enter a valid date", (v) => {
      if (v === null) return true;
      return moment(v, "YYYY/MM/DD").isValid();
    })
    .test(
      "is-valid",
      "End date should not be greater than start date",
      (v, ctx) => {
        if (v === null) return true;
        if (ctx.parent.start_date === null) return true;
        return (
          moment(ctx.parent.start_date, "YYYY/MM/DD").diff(
            moment(v, "YYYY/MM/DD")
          ) < 0
        );
      }
    )
    .transform((v) => serializeDate(v))
    .nullable(),
});

const FilterCard = ({ fetchData }) => {
  const { translate } = useLocales();

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const onSubmit = async (inputData) => {
    await fetchData(inputData);
  };
  const {
    formState: { isSubmitting },
  } = methods;
  return (
    <div>
      <Card sx={{ p: 3 }}>
        <Box>
          <Typography variant="subtitle2">
            {translate("userFinancial.eWallet.walletHistory")}
          </Typography>
          <FormProvider
            methods={methods}
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <Box
              sx={{
                display: "grid",
                rowGap: 3,
                columnGap: 2,
                marginTop: 3,
                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(4, 1fr)",
                },
              }}
            >
              <RHFDatePicker
                name="start_date"
                label="Pick Start Date"
                size="small"
              />
              <RHFDatePicker
                name="end_date"
                label="Pick End Date"
                size="small"
              />

              <UsersSearch name="from_id" props={{ size: "small" }} />
              <AmountType />
            </Box>
            <Box mt={2} sx={{ float: "right" }}>
              <LoadingButton
                loading={isSubmitting}
                type="submit"
                variant="contained"
                name="get-report"
              >
                {translate("userFinancial.eWallet.getReport")}
              </LoadingButton>
            </Box>
          </FormProvider>
        </Box>
      </Card>
    </div>
  );
};

export default FilterCard;
