import { PATH_DASHBOARD, PATH_USER } from "src/routes/paths";
import objectToQueryString from "src/utils/object-to-query-string";
import squashPathAndQueryString from "src/utils/squash-path-and-query-string";
const getIconURI = (name) => `/icons/${name}.svg`;

const ICONS = {
  blog: getIconURI("ic_blog"),
  cart: getIconURI("ic_cart"),
  chat: getIconURI("ic_insta"),
  mail: getIconURI("ic_mail"),
  user: getIconURI("ic_user"),
  kanban: getIconURI("ic_kanban"),
  banking: getIconURI("ic_banking"),
  booking: getIconURI("ic_booking"),
  invoice: getIconURI("ic_invoice"),
  calendar: getIconURI("ic_calendar"),
  ecommerce: getIconURI("ic_ecommerce"),
  analytics: getIconURI("ic_analytics"),
  dashboard: getIconURI("ic_dashboard"),
  settings: getIconURI("ic_settings"),
  tools: getIconURI("ic_tools"),
  member_management: getIconURI("ic_member_management"),
  subadmin: getIconURI("ic_subadmin"),
  holdingtank: getIconURI("ic_holdingtank"),
  store: getIconURI("ic_store"),
  user_subscription: getIconURI("ic_user_subscription"),
  report: getIconURI("ic_report"),
  online_store: getIconURI("ic_store"),
  my_subscription: getIconURI("ic_user_subscription"),
  business_builder: getIconURI("ic_bb"),
  profile: getIconURI("ic_profile"),
  help_center: getIconURI("ic_helpcenter"),
};

const userNavConfig = [
  {
    items: [
      {
        title: "dashboard",
        path: PATH_USER.user_dashboard,
        icon: ICONS.dashboard,
        placement: 0,
      },

      {
        title: "Instagram",
        path: "https://www.instagram.com/tugee.mn?igsh=ZmU3ZjU4OGRoaTdu&utm_source=qr",
        icon: ICONS.chat,
      },

      {
        title: "onlineStore",
        path: PATH_USER.onlineStore.root,
        icon: ICONS.online_store,
        placement: 1,
        children: [
          {
            title: "product",
            path: PATH_USER.onlineStore.productSubscription.root,
            placement: 0,
          },
          {
            title: "myOrders",
            path: PATH_USER.my_orders.root,
            placement: 1,
          },
        ],
      },
      {
        title: "mySubscriptions",
        icon: ICONS.my_subscription,
        path: PATH_USER.subscriptions.root,
        placement: 2,
      },
      {
        title: "genealogy",
        icon: ICONS.kanban,
        path: PATH_USER.genealogy.root,
        placement: 3,
        children: [
          {
            title: "binary",
            path: PATH_USER.genealogy.binary,
            placement: 0,
          },
          {
            title: "Sponsor",
            path: PATH_USER.genealogy.sponsor,
            placement: 1,
          },
          {
            title: "affiliateDashboard",
            path: PATH_USER.genealogy.affiliate,
            placement: 2,
          },
          {
            title: "binaryLegSettings",
            path: PATH_USER.genealogy.binaryLeg,
            placement: 3,
          },
        ],
      },
      {
        title: "financial",
        path: PATH_USER.financial.root,
        icon: ICONS.ecommerce,
        placement: 4,
        children: [
          {
            title: "myEWallet",
            path: PATH_USER.financial.eWallet,
            placement: 0,
          },
          {
            title: "binaryBonus",
            path: PATH_USER.financial.eWallet,
            placement: 0,
          },
          {
            title: "rank",
            path: PATH_USER.financial.eWallet,
            placement: 0,
          },
          {
            title: "directInvite",
            path: PATH_USER.financial.eWallet,
            placement: 0,
          },
          {
            title: "linkBonus",
            path: PATH_USER.financial.eWallet,
            placement: 0,

          },
        
          // {
          //   title: "depositWallet",
          //   path: PATH_USER.financial.depositWallet,
          //   placement: 1,
          // },
          {
            title: "rankSalary",
            path: PATH_USER.financial.monthlySalary,
            placement: 0,
          },
          // {
          //   title: "depositWallet",
          //   path: PATH_USER.financial.depositWallet,
          //   placement: 1,
          // },
          {
            title: "fundTransfer",
            path: PATH_USER.financial.fundsTransfer,
            placement: 2,
          },
          {
            title: "requestPayout",
            path: PATH_USER.financial.payout,
            placement: 3,
          },
        ],
      },
      {
        title: "businessBuilder",
        icon: ICONS.business_builder,
        path: PATH_USER.business_builder.root,
        placement: 5,
        children: [
          {
            title: "subscriptions",
            path: PATH_USER.business_builder.subscriptions,
            placement: 0,
          },
          {
            title: "materials",
            path: PATH_USER.business_builder.materials.root,
            placement: 1,
          },
        ],
      },
      {
        title: "myProfile",
        icon: ICONS.profile,
        path: PATH_USER.profile.root,
        placement: 6,
      },
      {
        title: "blogs",
        icon: ICONS.blog,
        path: PATH_USER.blogs.root,
        placement: 7,
      },
      {
        title: "helpCenter",
        path: PATH_USER.helpCenter.root,
        icon: ICONS.help_center,
        placement: 8,
        children: [
          {
            title: "faqs",
            path: PATH_USER.helpCenter.faq.root,
            placement: 0,
          },
          {
            title: "knowledgeBase",
            path: PATH_USER.helpCenter.knowledgeBase.root,
            placement: 1,
          },
          {
            title: "emails",
            path: PATH_USER.helpCenter.mails.inbox,
            placement: 2,
          },
          {
            title: "supportTickets",
            path: PATH_USER.helpCenter.createTicket.view,
            placement: 3,
          },
          {
            title: "documents",
            path: PATH_USER.helpCenter.documents,
            placement: 4,
          },
          {
            title: "videos",
            path: PATH_USER.helpCenter.videos,
            placement: 5,
          },
        ],
      },
      {
        title: "incomeReport",
        path: PATH_USER.incomeReport.root,
        icon: ICONS.report,
        placement: 9,
      },
    ],
  },
];

export const adminNavConfig = [
  {
    items: [
      {
        title: "dashboard",
        path: PATH_DASHBOARD.dashboard.root,
        icon: ICONS.dashboard,
        placement: 1,
        children: [
          {
            title: "business",
            parent: "dashboard",
            path: PATH_DASHBOARD.dashboard.business,
            placement: 0,
          },
          {
            title: "network",
            parent: "dashboard",
            path: PATH_DASHBOARD.dashboard.network,
            placement: 1,
          },
        ],
      },

      {
        title: "genealogy",
        parent: "genealogy",
        path: PATH_DASHBOARD.genealogy.root,
        icon: ICONS.kanban,
        placement: 2,
        children: [
          {
            title: "binary",
            parent: "genealogy",
            path: PATH_DASHBOARD.genealogy.binary,
            placement: 0,
          },
          {
            title: "sponsor",
            parent: "genealogy",
            path: PATH_DASHBOARD.genealogy.sponsor,
            placement: 1,
          },
          {
            title: "tree",
            parent: "genealogy",
            path: PATH_DASHBOARD.genealogy.tree,
            placement: 2,
          },
        ],
      },
      {
        title: "financial",
        path: PATH_DASHBOARD.financial.root,
        icon: ICONS.ecommerce,
        placement: 3,
        children: [
          {
            title: "ewallet",
            parent: "financial",
            path: PATH_DASHBOARD.financial.ewallet,
            placement: 0,
          },
          {
            title: "binaryBonus",
            parent: "financial",
            path: PATH_DASHBOARD.financial.binaryBonus,
            placement: 0,
          },
          {
            title: "rank",
            parent: "financial",
            path: PATH_DASHBOARD.financial.rank,
            placement: 0,
          },
          {
            title: "directInvite",
            parent: "financial",
            path: PATH_DASHBOARD.financial.directInvite,
            placement: 0,
          },
          {
            title: "linkBonus",
            parent: "financial",
            path: PATH_DASHBOARD.financial.linkBonus,
            placement: 0,
          },
          {
            title: "rankSalary",
            parent: "financial",
            path: PATH_DASHBOARD.financial.monthlySalary,
            placement: 0,
          },
          {
            title: "depositWallet",
            parent: "financial",
            path: PATH_DASHBOARD.financial.deposit_wallet,
            placement: 1,
          },
          {
            title: "fundCredits",
            parent: "financial",
            path: PATH_DASHBOARD.financial.fund_credits,
            placement: 2,
            actions: ["add", "deduct"],
          },
          {
            title: "payout",
            parent: "financial",
            path: PATH_DASHBOARD.financial.payout,
            placement: 3,
            actions: ["reject", "approve"],
          },
        ],
      },
      {
        title: "communication",
        path: PATH_DASHBOARD.communication.root,
        icon: ICONS.chat,
        placement: 4,
        children: [
          {
            title: "blog",
            parent: "communication",
            path: PATH_DASHBOARD.communication.blog,
            placement: 0,
            actions: ["add", "category", "edit", "delete", "draft", "publish"],
          },
          {
            title: "faqs",
            parent: "communication",
            path: PATH_DASHBOARD.communication.com_faqs,
            placement: 1,
            actions: [
              "add-faq",
              "edit-faq",
              "delete-faq",
              "category",
              "add-category",
              "edit-category",
              "delete-category",
            ],
          },
          {
            title: "emails",
            parent: "communication",
            path: PATH_DASHBOARD.communication.mails,
            placement: 2,
            actions: ["send", "delete"],
          },
          {
            title: "helpCenter",
            parent: "communication",
            path: PATH_DASHBOARD.communication.help_center,
            placement: 3,
            actions: [
              "create-ticket",
              "update-ticket-status",
              "view-ticket",
              "impersonate-ticket",
              "edit-ticket",
              "delete-ticket",
              "add-department",
              "edit-department",
              "delete-department",
              "add-categories",
              "edit-categories",
              "delete-categories",
              "add-canned",
              "edit-canned",
              "delete-canned",
              "add-priorities",
              "edit-priorities",
              "delete-priorities",
            ],
          },
          {
            title: "article",
            parent: "communication",
            path: PATH_DASHBOARD.communication.com_article,
            placement: 4,
            action: [
              "add-article",
              "edit-article",
              "delete-article",
              "add-category",
              "edit-category",
              "delete-category",
            ],
          },
        ],
      },

      {
        title: "tools",
        path: PATH_DASHBOARD.tools.root,
        icon: ICONS.tools,
        placement: 5,
        children: [
          {
            title: "documents",
            parent: "tools",
            path: PATH_DASHBOARD.tools.documents,
            placement: 0,
            actions: ["add", "edit", "delete"],
          },
          {
            title: "videos",
            parent: "tools",
            path: PATH_DASHBOARD.tools.videos,
            placement: 1,
            actions: ["add", "edit", "delete"],
          },
        ],
      },
      {
        title: "membersManagement",
        path: PATH_DASHBOARD.members.root,
        icon: ICONS.member_management,
        placement: 6,
        children: [
          {
            title: "networkMembers",
            parent: "membersManagement",
            path: PATH_DASHBOARD.members.network,
            placement: 0,
          },
          {
            title: "holdingTank",
            parent: "membersManagement",
            path: PATH_DASHBOARD.members.holdingTank,
            placement: 1,
            actions: ["add"],
          },
        ],
      },

      {
        disabled: true,
        title: "subAdmin",
        path: PATH_DASHBOARD.subAdmin.root,
        icon: ICONS.subadmin,
        children: [
          {
            title: "subAdmins",
            path: PATH_DASHBOARD.subAdmin.sub_admins,
          },
        ],
      },

      {
        title: "store",
        path: PATH_DASHBOARD.store.root,
        icon: ICONS.store,
        placement: 8,
        children: [
          {
            title: "products",
            parent: "store",
            path: PATH_DASHBOARD.store.products,
            placement: 0,
            actions: [
              "add-package",
              "add-product",
              "add-category",
              "view-edit-video",
              "view-edit-document",
              "view-edit-questions",
              "view-edit-sampledocument",
              "view-edit-product",
              "delete",
            ],
          },
          {
            title: "packages",
            parent: "store",
            path: PATH_DASHBOARD.store.packages,
            placement: 0,
            actions: [
              "add-package",
              "add-product",
              "add-category",
              "view-edit-video",
              "view-edit-document",
              "view-edit-questions",
              "view-edit-sampledocument",
              "view-edit-product",
              "delete",
            ],
          },
          {
            title: "materials",
            parent: "store",
            path: PATH_DASHBOARD.store.material,
            placement: 1,
            actions: [
              "add-category",
              "add-material",
              "view-material",
              "view-document",
              "edit-document",
              "delete-document",
              "edit-video",
              "delete-video",
            ],
          },
          {
            title: "events",
            parent: "store",
            path: PATH_DASHBOARD.store.events,
            placement: 2,
            actions: ["add", "edit", "delete"],
          },
          {
            title: "Coupons",
            parent: "store",
            path: PATH_DASHBOARD.store.coupons,
            placement: 3,
            actions: ["add", "edit", "delete"],
          },
          {
            title: "userReviews",
            parent: "store",
            path: PATH_DASHBOARD.store.user_reviews,
            placement: 4,
            actions: ["add", "edit", "delete", "view"],
          },

          {
            title: "assignSubscriptions",
            parent: "store",
            path: PATH_DASHBOARD.store.assign_subscriptions,
            placement: 5,
            actions: [
              "add-product",
              "edit-product",
              "delete-product",
              "add-category",
              "edit-category",
              "delete-category",
            ],
          },
          {
            title: "businessBuilderSubscriptionssales",
            parent: "store",
            path: PATH_DASHBOARD.store.business_builder_subscriptions,
            placement: 6,
          },
          {
            title: "paymentMethods",
            path: PATH_DASHBOARD.store.paymentMethods,
            parent: "store",
          },
        ],
      },
      {
        title: "settings",
        path: PATH_DASHBOARD.settings.root,
        icon: ICONS.settings,
        placement: 9,
        children: [
          {
            title: "brand",
            parent: "settings",
            path: PATH_DASHBOARD.settings.brand,
            placement: 0,
          },
          {
            title: "businessBuilder",
            parent: "settings",
            path: PATH_DASHBOARD.settings.business,
            placement: 1,
          },
          {
            title: "network",
            parent: "settings",
            path: PATH_DASHBOARD.settings.network.root,
            placement: 2,
          },
          {
            title: "Withdrawal",
            parent: "settings",
            path: PATH_DASHBOARD.settings.withdrawal,
            placement: 3,
          },
        ],
      },
      {
        title: "reports",
        path: PATH_DASHBOARD.report.root,
        icon: ICONS.report,
        placement: 10,
        children: [
          {
            title: "builderSubscription",
            parent: "reports",
            path: PATH_DASHBOARD.report.builder,
            placement: 0,
          },
          {
            title: "fundCredit",
            parent: "reports",
            path: PATH_DASHBOARD.report.fund_credit,
            placement: 1,
          },
          {
            title: "joiningReport",
            parent: "reports",
            path: PATH_DASHBOARD.report.joining,
            placement: 2,
          },
          {
            title: "memberIncome",
            parent: "reports",
            path: PATH_DASHBOARD.report.member_income,
            placement: 3,
          },
          {
            title: "payout",
            parent: "reports",
            path: PATH_DASHBOARD.report.payout_report,
            placement: 4,
          },
          {
            title: "pointHistory",
            parent: "reports",
            path: PATH_DASHBOARD.report.point_history,
            placement: 5,
          },
          {
            title: "sales",
            parent: "reports",
            path: PATH_DASHBOARD.report.sales_report,
            placement: 6,
          },
          {
            title: "topEarners",
            parent: "reports",
            path: PATH_DASHBOARD.report.top_earners,
            placement: 7,
          },
        ],
      },
    ],
  },
];

export default userNavConfig;
