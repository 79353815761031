import { lazy } from "react";
import { Navigate } from "react-router";
import DepositWallet from "src/pages/user/financial/deposit";
import Ewallet from "src/pages/user/financial/ewallet";
import FundTransfer from "src/pages/user/financial/fundTransfer";
import Loadable from "../Loadable";

const AddCredit = Loadable(
  lazy(() => import("src/pages/user/financial/deposit/addCredit"))
);

const RequestPayout = Loadable(
  lazy(() => import("src/pages/user/financial/requestPayout/index"))
);
const RankSalary = Loadable(
  lazy(() => import("src/pages/user/financial/rankSalary/index"))
);
const LinkBonus = Loadable(
  lazy(() => import("src/pages/user/financial/linkBonus/index"))
);
const DirectInvite = Loadable(
  lazy(() => import("src/pages/user/financial/directInvite/index"))
);
const Rank = Loadable(
  lazy(() => import("src/pages/user/financial/rank/index"))
);
const BinaryBonus = Loadable(
  lazy(() => import("src/pages/user/financial/binaryBonus/index"))
);
const MatchingBonus = Loadable(
  lazy(() => import("src/pages/user/financial/matchingBonus/index"))
);

const financial = {
  path: "financial",
  children: [
    { index: true, element: <Navigate to="e-wallet" /> },
    { path: "e-wallet", element: <Ewallet /> },
    { path: "binary-bonus", element: <BinaryBonus /> },
    { path: "rank", element: <Rank /> },
    { path: "direct-invite", element: <DirectInvite /> },
    { path: "link-bonus", element: <LinkBonus /> },
    { path: "matching-bonus", element: <MatchingBonus /> },

    { path: "monthly-salary", element: <RankSalary /> },

    {
      path: "deposit-wallet",
      children: [
        { index: true, element: <DepositWallet /> },
        { path: "add-credit", element: <AddCredit /> },
      ],
    },
    { path: "funds-transfer", element: <FundTransfer /> },
    { path: "payout", element: <RequestPayout /> },
  ],
};

export default financial;
