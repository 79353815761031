import { lazy } from "react";
import { Navigate } from "react-router";
import Loadable from "src/routes/Loadable";

const BrandSettings = Loadable(
  lazy(() => import("src/pages/admin/settings/brand"))
);
const BusinessSettings = Loadable(
  lazy(() => import("src/pages/admin/settings/business/index"))
);
const NetworkSettings = Loadable(
  lazy(() => import("src/pages/admin/settings/network/index"))
);

const Withdrawal = Loadable(
  lazy(() => import("src/pages/admin/settings/withdrawal/index"))
);

const Referral = {
  binary: Loadable(
    lazy(() =>
      import("src/pages/admin/settings/network/@pages/directBonus/index")
    )
  ),
  nonBinary: Loadable(
    lazy(() => import("src/pages/admin/settings/network/@pages/settings/index"))
  ),
};

const Bonus = Loadable(
  lazy(() => import("src/pages/admin/settings/network/@pages/first/index"))
);

const Level = Loadable(
  lazy(() => import("src/pages/admin/settings/network/@pages/level/index"))
);
const Bronze = Loadable(
  lazy(() => import("src/pages/admin/settings/network/@pages/binary/index"))
);

const Roi = Loadable(
  lazy(() => import("src/pages/admin/settings/network/@pages/roi/index"))
);
const Binary = Loadable(
  lazy(() => import("src/pages/admin/settings/network/@pages/binary/index"))
);
const Rank = Loadable(
  lazy(() =>
    import("src/pages/admin/settings/network/@pages/rank-settings/index")
  )
);

const LinkBonus = Loadable(
  lazy(() => import("src/pages/admin/settings/network/@pages/linkBonus/index"))
);

const Stair = Loadable(
  lazy(() => import("src/pages/admin/settings/network/@pages/stair/index"))
);
const RankSalary = Loadable(
  lazy(() => import("src/pages/admin/settings/network/@pages/rankSalary/index"))
);
const settings = [
  {
    path: "settings",
    children: [
      { element: <Navigate to="brand" />, index: true },
      { path: "brand", element: <BrandSettings /> },
      {
        path: "business",
        element: <BusinessSettings />,
      },
      {
        path: "network",
        element: <NetworkSettings />,
        children: [
          {
            index: true,
            element: <Navigate to="direct" />,
          },
          {
            path: "direct",
            element: <Referral.binary />,
          },
          {
            path: "linkBonus",
            element: <LinkBonus />,
          },
          {
            path: "rankSalary",
            element: <RankSalary />,
          },
          {
            path: "bonus",
            element: <Bonus />,
          },
          {
            path: "level",
            element: <Level />,
          },
          {
            path: "stair",
            element: <Stair />,
          },
          {
            path: "binary",
            element: <Binary />,
          },
          {
            path: "rank",
            element: <Rank />,
          },
          {
            path: "bronze",
            element: <Bronze />,
          },
          {
            path: "roi",
            element: <Roi />,
          },
        ],
      },
      { path: "withdrawal", element: <Withdrawal /> },
    ],
  },
];

export default settings;
