import { LoadingButton } from "@mui/lab";
import { Box, Link, Stack } from "@mui/material";
import { capitalCase } from "change-case";
import { Link as RouterLink } from "react-router-dom";
import { PLANS } from "src/CONSTANTS";
import Password from "src/components/Password";
import {
  FormProvider,
  RHFCheckbox,
  RHFSelect,
  RHFTextField,
} from "src/components/hook-form";
import Map from "src/components/map";
import Ternary from "src/components/ternary";
import { PATH_AUTH } from "src/routes/paths";
import { usePlan } from "src/store/plan";
import AuthHelper from "./components/authHelper";
import EnterOtp from "./components/enter-otp";
import ErrorBanner from "./components/error-banner";
import useLogin from "./hooks/use-login";

const LoginForm = () => {
  const { secret, onSubmit, methods } = useLogin();
  const plan = usePlan();

  const {
    watch,
    formState: { isSubmitting },
  } = methods;

  const data = watch();

  return (
    <>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Stack spacing={2} sx={{ mb: 3 }}>
          <AuthHelper />
          <ErrorBanner />
        </Stack>

        <Box>
          <Stack spacing={2}>
            <RHFTextField name="email" label="Email address" />
            <Password name="password" label="Password" />

            <Ternary
              when={!plan}
              then={
                <RHFSelect name="plan" label="Choose Plan">
                  <Map
                    list={Object.entries(PLANS)}
                    render={([k, v]) => (
                      <option value={v} key={v}>
                        {capitalCase(k)}
                      </option>
                    )}
                  />
                </RHFSelect>
              }
            />
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ my: 2 }}
          >
            <RHFCheckbox name="remember" label="Remember me" />
            <Link
              component={RouterLink}
              variant="subtitle2"
              to={PATH_AUTH.resetPassword}
            >
              Forgot password?
            </Link>
          </Stack>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            name="login"
          >
            Login
          </LoadingButton>
        </Box>
      </FormProvider>

      <EnterOtp secret={secret} data={data} />
    </>
  );
};

export default LoginForm;
