import { TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import moment from "moment";
import { Controller, useFormContext } from "react-hook-form";
import { DATE_FORMAT } from "src/config";

const RHFDatePicker = ({ name, label, size }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <DatePicker
            label={label}
            inputFormat={DATE_FORMAT}
            value={field.value}
            onChange={(newValue) => {
              if (newValue) {
                const date = moment(dayjs(newValue).format("MM/DD/YYYY"));
                field.onChange(date);
              } else {
                field.onChange(null);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                error={!!error}
                helperText={error?.message}
                size={size}
              />
            )}
          />
        );
      }}
    />
  );
};

export default RHFDatePicker;
